import React from "react";
import PropTypes from "prop-types";
import useGetBusinessEntitiesQuery from "../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import SectionTitle from "../../../SectionTitle";
import BusinessEntity from "./BusinessEntity";

function Provider({ provider }) {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId: provider.id,
        includeIncomplete: false,
    });

    return businessEntities ? (
        <div className="flex flex-col px-[15px] pt-[15px] pb-[10px] gap-y-[20px] border-[1px] rounded-xl shadow-lg">
            <div className="flex flex-row justify-between items-center gap-x-[10px]">
                <SectionTitle>{provider.legal_name}</SectionTitle>
                <div className="text-sm text-flychainGray">
                    NPI: {provider.npi}
                </div>
            </div>
            <div className="flex flex-col gap-y-[15px]">
                {businessEntities.map((businessEntity) => (
                    <>
                        <div
                            key={`${businessEntity.id}-div`}
                            className="border-t-[1px] border-gray-300"
                        />
                        <BusinessEntity
                            key={businessEntity.id}
                            businessEntity={businessEntity}
                        />
                    </>
                ))}
            </div>
        </div>
    ) : null;
}

Provider.propTypes = {
    provider: PropTypes.object.isRequired,
};

export default Provider;
