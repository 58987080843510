import React from "react";
import FlychainLogoSvg from "../../../../assets/FlychainLogo.svg";
import MercuryLogoSvg from "../../../../assets/MercuryLogo.svg";

function Logos() {
    return (
        <div className="flex flex-row gap-x-[30px] items-center">
            <img
                src={FlychainLogoSvg}
                alt="FlychainLogo"
                className="h-[35px]"
            />
            <span className="text-flychainGray text-[30px] font-medium">x</span>
            <img src={MercuryLogoSvg} alt="MercuryLogo" className="h-[35px]" />
        </div>
    );
}

export default Logos;
