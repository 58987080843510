import React from "react";
import HeaderSection from "../HeaderSection";
import ProductSummary, { productSummaryTitles } from "../ProductSummary";
import QualificationRequirements, {
    qualificationRequirementsTitles,
} from "../QualificationRequirements";
import TermLoanGraphicSvg from "../../../assets/TermLoanGraphic.svg";

const productSummaryRows = [
    [
        productSummaryTitles.USE_OF_FUNDS,
        "Funds can be used for any business purpose including debt consolidation",
    ],
    [productSummaryTitles.LOAN_AMOUNT, "$5,000 to $5,000,000"],
    [productSummaryTitles.TIME_TO_FUND, "Funding as soon as 5 business days"],
    [productSummaryTitles.REPAYMENT_PERIOD, "6 months to 2 years"],
    [
        productSummaryTitles.INTEREST_RATE,
        "16-25% annualized interest rate depending on your credit profile",
    ],
];

const qualificationRequirementsRows = [
    [qualificationRequirementsTitles.TIME_IN_BUSINESS, "At least 1 year"],
    [qualificationRequirementsTitles.FICO, "At least 580"],
];

function TermLoan() {
    const onClick = () => {
        window.open(
            "https://breakoutfinance.formcrafts.com/andrew-moss-term-loan",
            "_blank"
        );
    };
    return (
        <div className="flex flex-col">
            <HeaderSection
                title="Term Loan"
                body="Our term loans are best used when you have a specific expense or growth initiative that you would like to finance with a repayment period of 6 months to 2 years. Our term loans are also great for consolidating existing debt. Qualification requirements are less strict than the SBA or Line of Credit and we can fund as soon as 5 business days."
                buttonText="Apply Now"
                onClick={onClick}
                graphic={
                    <img
                        className="h-[30vh]"
                        src={TermLoanGraphicSvg}
                        alt="TermLoanGraphicSvg"
                    />
                }
            />
            <div className="flex flex-col p-[60px] gap-y-[50px]">
                <ProductSummary rows={productSummaryRows} />
                <QualificationRequirements
                    rows={qualificationRequirementsRows}
                />
            </div>
        </div>
    );
}

export default TermLoan;
