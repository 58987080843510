import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";

function BusinessEntityWrapper({ children }) {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: false,
    });
    const { businessEntityId } = useParams();

    if (!businessEntityId) {
        throw new Error("No businessEntityId path param in url");
    }

    if (!businessEntities) {
        return null;
    }

    const matchingEntity = businessEntities.find(
        (entity) => entity.id === businessEntityId
    );
    if (!matchingEntity) {
        throw new Error(
            `No businessEntity with id = ${businessEntityId} found in businessEntities with financial data`
        );
    }

    return children;
}

BusinessEntityWrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export default BusinessEntityWrapper;
