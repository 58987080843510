import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../SectionTitle";

function Table({ title, rows }) {
    return (
        <div className="flex flex-col">
            <SectionTitle>{title}</SectionTitle>
            <div className="my-[16px] h-[1px] bg-[#f2f2f2]" />
            {rows.map(([rowTitle, body]) => (
                <div key={rowTitle} className="flex flex-row my-[16px]">
                    <div className="font-semibold text-[15px] flex-[1]">
                        {rowTitle}
                    </div>
                    <div className="font-normal text-[15px] flex-[2]">
                        {body}
                    </div>
                </div>
            ))}
        </div>
    );
}

Table.propTypes = {
    title: PropTypes.string.isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
    ).isRequired,
};

export default Table;
