import React from "react";
import PropTypes from "prop-types";
import {
    useUpdatePlaidAccountMappingMutation,
    useGetPlaidAccountMappingsQuery,
} from "../../../../../redux/slices/adminApi";
import useGetBusinessEntitiesQuery from "../../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import Select from "../../../../Select";

const businessEntityForPlaidAccount = (businessEntities, businessEntityId) => {
    const foundEntity = businessEntities.find(
        (entity) => entity.id === businessEntityId
    );
    return foundEntity;
};

const accountNameString = (rawPlaidAccount) =>
    `${rawPlaidAccount.name} - ${rawPlaidAccount.mask}`;

const businessEntityString = (businessEntity) =>
    `${businessEntity.legal_name} - ${businessEntity.id}`;

const dropdownOptions = (businessEntities) =>
    businessEntities.map((entity) => ({
        value: entity.id,
        label: businessEntityString(entity),
    }));

function Table({ providerId }) {
    // use this to populate the dropdown
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        includeIncomplete: true,
    });
    const { data: plaidAccountMappings } =
        useGetPlaidAccountMappingsQuery(providerId);

    const [updatePlaidAccountMapping] = useUpdatePlaidAccountMappingMutation();

    const onClick = (newBusinessEntityId, internalPlaidAccountId) => {
        updatePlaidAccountMapping({
            providerId,
            body: {
                new_business_entity_id: newBusinessEntityId,
                internal_plaid_account_id: internalPlaidAccountId,
            },
        });
    };
    return plaidAccountMappings && businessEntities ? (
        <div className="flex flex-col justify-start">
            <div className="my-[30px]">
                <table className="border-separate border-spacing-x-[16px] border-spacing-y-[32px]">
                    <thead>
                        <tr>
                            <th>
                                <div>Plaid Account ID</div>
                            </th>
                            <th>
                                <div>Active Plaid Item?</div>
                            </th>
                            <th>
                                <div>Plaid Account Name + Mask</div>
                            </th>
                            <th>
                                <div>Business Entity</div>
                            </th>
                            <th>
                                <div>Teal Instance Linked?</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {plaidAccountMappings.map((plaidAccountMapping) => {
                            const businessEntity =
                                businessEntityForPlaidAccount(
                                    businessEntities,
                                    plaidAccountMapping.business_entity_id
                                );
                            return (
                                <tr
                                    key={plaidAccountMapping.id}
                                    className={
                                        plaidAccountMapping.internal_teal_instance_id
                                            ? "bg-[#31c4cf]"
                                            : ""
                                    }
                                >
                                    <td>
                                        <div>{plaidAccountMapping.id}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {plaidAccountMapping.is_active ? (
                                                <div>Yes</div>
                                            ) : (
                                                <div>No</div>
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {plaidAccountMapping.raw_plaid_account ? (
                                                <div>
                                                    {accountNameString(
                                                        plaidAccountMapping.raw_plaid_account
                                                    )}
                                                </div>
                                            ) : (
                                                <div>Not Found</div>
                                            )}
                                        </div>
                                    </td>
                                    <td>
                                        {plaidAccountMapping.internal_teal_instance_id ? (
                                            <div>
                                                {businessEntityString(
                                                    businessEntity
                                                )}{" "}
                                            </div>
                                        ) : (
                                            <div>
                                                <Select
                                                    label="Remap to New Business Entity"
                                                    value={businessEntity.id}
                                                    options={dropdownOptions(
                                                        businessEntities
                                                    )}
                                                    onChange={(event) =>
                                                        onClick(
                                                            event.value,
                                                            plaidAccountMapping.id
                                                        )
                                                    }
                                                    alignment="right"
                                                >
                                                    {" "}
                                                </Select>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {plaidAccountMapping.internal_teal_instance_id ? (
                                            <div>
                                                {
                                                    plaidAccountMapping.internal_teal_instance_id
                                                }
                                            </div>
                                        ) : (
                                            <div>Not Linked</div>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    ) : null;
}

Table.propTypes = {
    providerId: PropTypes.string.isRequired,
};

export default Table;
