import React from "react";
import PropTypes from "prop-types";
import Card from "../../../Card";
import Button from "../../../Button";
import SectionTitle from "../../../SectionTitle";

function CapitalProduct({ title, body, onClick, comingSoon }) {
    const content = (
        <>
            <SectionTitle>{title}</SectionTitle>
            <div className="flex flex-col justify-between items-center grow gap-y-[20px]">
                <div className="text-center mt-[20px] text-flychainGray font-normal">
                    {body}
                </div>
                {onClick ? (
                    <Button
                        onClick={onClick}
                        className="bg-flychainPurple-light text-white inline-block rounded-full px-[16px] py-[12px] w-fit"
                    >
                        Learn More
                    </Button>
                ) : null}
            </div>
        </>
    );

    return comingSoon ? (
        <Card
            showTab
            tabText="Coming Soon"
            tabColor="green"
            className="flex flex-col items-center w-full"
            tabContainerClassName="w-5/12"
        >
            {content}
        </Card>
    ) : (
        <Card showTab={false} className="flex flex-col items-center w-5/12">
            {content}
        </Card>
    );
}

CapitalProduct.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    comingSoon: PropTypes.bool,
};

CapitalProduct.defaultProps = {
    onClick: undefined,
    comingSoon: false,
};

export default CapitalProduct;
