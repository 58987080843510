import React from "react";
import PropTypes from "prop-types";
import {
    useGetPlaidAccountMappingsQuery,
    useOnboardProviderToTealMutation,
} from "../../../../../redux/slices/adminApi";
import useGetBusinessEntitiesQuery from "../../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import SectionTitle from "../../../../SectionTitle";
import Button from "../../../../Button";

const accountNameString = (rawPlaidAccount) =>
    `${rawPlaidAccount.name} - ${rawPlaidAccount.mask}`;

const businessEntityString = (businessEntity) =>
    `${businessEntity.legal_name} - ${businessEntity.id}`;

const canLink = (plaidAccountMapping) =>
    // there is a t least one plaid account that is unlinked
    plaidAccountMapping.some((mapping) => !mapping.internal_teal_instance_id);

function ReadyToLink({ providerId }) {
    // use this to populate the dropdown
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        includeIncomplete: true,
    });
    const { data: plaidAccountMappings } =
        useGetPlaidAccountMappingsQuery(providerId);
    const [onboardProviderToTeal] = useOnboardProviderToTealMutation();

    const handleOnboardProviderToTeal = () => {
        onboardProviderToTeal({ body: { provider_id: providerId } });
    };

    return plaidAccountMappings && businessEntities ? (
        <div>
            <div>
                <SectionTitle> Ready to Link: </SectionTitle>
            </div>
            <div>
                Note that undoing this linking process is involved and fairly
                manual. Please make sure that the accounts match these business
                entities. If not please go back to Mappings and rewire the
                accounts. Also, plaid accounts associated with inactive plaid
                items will not be shown.
            </div>
            <Button
                disabled={!canLink(plaidAccountMappings)}
                onClick={handleOnboardProviderToTeal}
            >
                Link
            </Button>
            {businessEntities.map((businessEntity) => (
                <div key={businessEntity.id}>
                    <h3>{businessEntityString(businessEntity)}</h3>
                    {plaidAccountMappings
                        .filter(
                            (mapping) =>
                                mapping.business_entity_id ===
                                    businessEntity.id &&
                                !mapping.internal_teal_instance_id &&
                                mapping.is_active
                        )
                        .map((mapping) => (
                            <div key={mapping.id}>
                                {accountNameString(mapping.raw_plaid_account)}
                                {/* Add link button or other UI elements here */}
                            </div>
                        ))}
                </div>
            ))}
        </div>
    ) : null;
}

ReadyToLink.propTypes = {
    providerId: PropTypes.string.isRequired,
};

export default ReadyToLink;
