import React from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../PageTitle";
import SectionTitle from "../../../SectionTitle";
import SelectedProvider from "../../FinancialReports/SelectedProvider";
import Create from "./Create";
import Table from "./Table";

function Entities() {
    const { providerId } = useParams();

    return (
        <div className="flex flex-col justify-start">
            <SelectedProvider providerId={providerId} />
            <div className="mb-[20px]">
                <PageTitle> Entities </PageTitle>
            </div>
            <div>
                <SectionTitle> Existing Business Entities </SectionTitle>
            </div>
            <div>
                {" "}
                Please ensure that all expected business entities are listed
                here. If not, please create them.{" "}
            </div>
            {/* Table will have name, business entity id and teal instance id */}
            {providerId && <Table providerId={providerId} />}
            <div>
                <SectionTitle> Create Business Entity </SectionTitle>
            </div>
            <Create providerId={providerId} />
            {/* view a list of entities with info like name, instance etc */}
            {/* button that takes in a legal name and creates entity, instance, creates books metadata and links instance to bookkeeper */}
        </div>
    );
}

export default Entities;
