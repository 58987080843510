/* eslint-disable camelcase */
import React from "react";
import ImproveFinancialHealth from "./ImproveFinancialHealth";
import ValuationEstimate from "./ValuationEstimate";
import ValuationCall from "./ValuationCall";
import ValuationExplanation from "./ValuationExplanation";
import useGetValuationQuery from "../../hooks/queriesAndMutations/useGetValuationQuery";
import isSufficientData from "./ValuationEstimate/ValuationRange/utils";

function Valuation() {
    const { data: valuationData } = useGetValuationQuery();

    if (!valuationData) return null;

    const { start_date, end_date } = valuationData;

    const isDataSufficient = isSufficientData(start_date, end_date);

    return valuationData ? (
        <div className="flex flex-col justify-start">
            <ValuationEstimate />
            {isDataSufficient && <ValuationExplanation />}
            <ValuationCall />
            <ImproveFinancialHealth />
        </div>
    ) : null;
}

export default Valuation;
