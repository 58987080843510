import React from "react";

function Disclaimer() {
    return (
        <div className="flex flex-col bg-[#F2F2F2] p-[40px] font-normal text-[12px]">
            <p className="font-semibold">Disclaimer</p>
            <p>
                Flychain is a client of Treasure and is not compensated for
                client referrals.{" "}
                <a
                    href="https://www.treasurefi.com/disclosure/flychain-solicitation-disclosure"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Learn more
                </a>
                .
            </p>
            <p>
                Investment advisory services offered by Treasure Investment
                Management, LLC (“Treasure”), an investment adviser registered
                with the U.S. Securities and Exchange Commission
                (&quot;SEC&quot;). For important information and disclosures
                related to the services Treasure provides, please see
                Treasure&apos;s{" "}
                <a
                    href="https://www.treasurefi.com/adv-brochure"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Form ADV Brochure
                </a>
                . Brokerage services are provided by Apex Clearing Corporation
                (&quot;Apex&quot;), member FINRA/SIPC. Investing involves risk,
                including loss of principal. Past performance is not a guarantee
                of future returns. Treasure Cash accounts are held at
                Grasshopper Bank, N.A., member FDIC.
            </p>
        </div>
    );
}

export default Disclaimer;
