import React, { useState } from "react";
import PropTypes from "prop-types";
import DownCaret from "../../../../../assets/DownCaret.svg";
import RightCaret from "../../../../../assets/RightCaret.svg";

function BusinessEntityDetails({ businessEntity }) {
    const [detailsOpen, setDetailsOpen] = useState(false);

    function getAddress() {
        // First handle all parts except state and zip
        const mainParts = [
            businessEntity.street_address,
            businessEntity.city,
        ].filter(Boolean);

        // Combine state and zip without comma if both exist
        const stateZip = [businessEntity.state, businessEntity.postal_code]
            .filter(Boolean)
            .join(" ");

        // Combine all parts
        return [...mainParts, stateZip].filter(Boolean).join(", ");
    }

    return (
        <div className="flex flex-col gap-y-[8px]">
            <div
                className="flex flex-row gap-x-[8px] items-center w-fit cursor-pointer"
                onClick={() => setDetailsOpen(!detailsOpen)}
            >
                <div className="font-semibold">Details</div>
                <img
                    src={detailsOpen ? DownCaret : RightCaret}
                    alt="Toggle"
                    className="w-[10px] h-[10px] brightness-0"
                />
            </div>
            {detailsOpen && (
                <div className="flex flex-row">
                    <div className="flex flex-col gap-y-[3px] pl-[10px]">
                        <div>DBA:</div>
                        <div>Entity type:</div>
                        <div>Specialty:</div>
                        <div>Business start:</div>
                        <div>Address:</div>
                    </div>
                    <div className="flex flex-col gap-y-[3px] ml-[20px]">
                        <div>{businessEntity.dba || "UNKNOWN"}</div>
                        <div>
                            {businessEntity.business_entity_type || "UNKNOWN"}
                        </div>
                        <div>{businessEntity.specialty || "UNKNOWN"}</div>
                        <div>
                            {businessEntity.business_start_date_month ||
                            businessEntity.business_start_date_year
                                ? `${businessEntity.business_start_date_month} ${businessEntity.business_start_date_year}`
                                : "UNKNOWN"}
                        </div>
                        <div>{getAddress() || "UNKNOWN"}</div>
                    </div>
                </div>
            )}
        </div>
    );
}

BusinessEntityDetails.propTypes = {
    businessEntity: PropTypes.object.isRequired,
};

export default BusinessEntityDetails;
