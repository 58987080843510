import React, { useState } from "react";
import PropTypes from "prop-types";
import DownCaret from "../../../../../assets/DownCaret.svg";
import RightCaret from "../../../../../assets/RightCaret.svg";

function BusinessEntityContactInfo({ businessEntity }) {
    const [detailsOpen, setDetailsOpen] = useState(false);

    function formatPhoneNumber() {
        const phone = businessEntity.contact_phone.replace(/\D/g, ""); // Remove non-digits

        // Handle different phone number formats
        if (phone.length === 10) {
            return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
        }

        if (phone.length === 11) {
            return phone.replace(
                /(\d{1})(\d{3})(\d{3})(\d{4})/,
                "+$1 ($2) $3-$4"
            );
        }

        // If format doesn't match, return original number with proper spacing
        return phone.replace(/(\d{3})/g, "$1 ").trim();
    }

    return (
        <div className="flex flex-col gap-y-[8px]">
            <div
                className="flex flex-row gap-x-[8px] items-center w-fit cursor-pointer"
                onClick={() => setDetailsOpen(!detailsOpen)}
            >
                <div className="font-semibold">Contact Info</div>
                <img
                    src={detailsOpen ? DownCaret : RightCaret}
                    alt="Toggle"
                    className="w-[10px] h-[10px] brightness-0"
                />
            </div>
            {detailsOpen && (
                <div className="flex flex-row">
                    <div className="flex flex-col gap-y-[3px] pl-[10px]">
                        <div>Name:</div>
                        <div>Email:</div>
                        <div>Phone:</div>
                    </div>
                    <div className="flex flex-col gap-y-[3px] ml-[20px]">
                        <div>
                            {businessEntity.contact_full_name || "UNKNOWN"}
                        </div>
                        <div>{businessEntity.contact_email || "UNKNOWN"}</div>
                        <div>
                            {businessEntity.contact_phone
                                ? formatPhoneNumber()
                                : "UNKNOWN"}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

BusinessEntityContactInfo.propTypes = {
    businessEntity: PropTypes.object.isRequired,
};

export default BusinessEntityContactInfo;
