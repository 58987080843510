import React from "react";
import PageTitle from "../../../PageTitle";

function Check() {
    // placeholder for now
    return (
        <div className="flex flex-col justify-start p-[60px] gap-y-[20px]">
            <PageTitle> Check </PageTitle>
            <div className="flex flex-col gap-y-[30px]">
                <div className="font-light text-[15px]">
                    {" "}
                    Perform automated and manual checks to ensure the platform
                    is ready to view.
                </div>
                <div className="font-light text-[15px]">
                    {" "}
                    1. Go to admin financial reports and ensure all entities and
                    accounts look connected. Run debug calls to see all
                    transaction source accounts etc.
                </div>
                {/* add url nav here */}
                <div className="font-light text-[15px]">
                    {" "}
                    2. Communicate to Blubooks that bookkeeping can begin. When
                    bookkeeping is complete then proceed ...
                </div>
                <div className="font-light text-[15px]">
                    {" "}
                    3. Make signed_saas_agreement_url none null for provider
                </div>
                <div className="font-light text-[15px]">
                    {" "}
                    4. Set to platform_active
                </div>
                {/* update here? show if platform active */}
            </div>
        </div>
    );
}

export default Check;
