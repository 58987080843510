import React, { useEffect, useState, useRef } from "react";
import { useGetProvidersOnboardedToTealQuery } from "../../../redux/slices/adminApi";
import PageTitle from "../../PageTitle";
import StateInputField from "../../InputField/StateInputField";
import Provider from "./Provider";
import CloseSvg from "../../../assets/Close.svg";

function BookkeepingDates() {
    const scrollContainerRef = useRef(null);
    const { data: providersOnboardedToTeal } =
        useGetProvidersOnboardedToTealQuery();
    const [filteredProviders, setFilteredProviders] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        if (!providersOnboardedToTeal) return;

        const filtered = providersOnboardedToTeal.filter(
            (provider) =>
                provider.legal_name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase()) ||
                (provider.dba &&
                    provider.dba
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()))
        );

        setFilteredProviders(filtered);
    }, [searchTerm, providersOnboardedToTeal]);

    useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
        }
    }, [filteredProviders]);

    function clearSearch() {
        setSearchTerm("");
    }

    return providersOnboardedToTeal ? (
        <div className="flex flex-col h-full pt-[60px] pl-[60px] gap-y-[20px]">
            <PageTitle>Edit Bookkeeping Dates</PageTitle>
            <div className="flex flex-row items-center w-full gap-x-[10px] pr-[60px]">
                <StateInputField
                    className="p-2 w-full rounded-full border-2 border-gray-300 focus:outline-flychainPurple-light"
                    name="search"
                    type="text"
                    placeholder="Search for a provider"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <img
                    className="cursor-pointer h-[15px]"
                    src={CloseSvg}
                    alt="Close"
                    onClick={clearSearch}
                />
            </div>
            {filteredProviders.length > 0 ? (
                <div
                    ref={scrollContainerRef}
                    className="flex flex-col gap-y-[20px] overflow-y-auto scrollbar scrollbar-thumb-flychainPurple-light grow pr-[30px] pb-[30px] mr-[30px]"
                >
                    {filteredProviders.map((provider) => (
                        <Provider key={provider.id} provider={provider} />
                    ))}
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center h-full pb-[30px] pr-[60px]">
                    <div className="text-[24px] font-semibold">
                        No providers found
                    </div>
                </div>
            )}
        </div>
    ) : null;
}

export default BookkeepingDates;
