import React from "react";
import Alert from "../../Alert";
import PlaidItemsTable from "./PlaidItemsTable";
import SectionTitle from "../../SectionTitle";
import { useGetExpiredPlaidItemsQuery } from "../../../redux/slices/adminApi";

function ExpiredPlaidItems() {
    const { data: expiredPlaidItems } = useGetExpiredPlaidItemsQuery();

    return expiredPlaidItems ? (
        <div className="flex flex-col h-full gap-y-[10px]">
            <div className="flex flex-col px-[20px] pt-[20px] gap-y-[20px]">
                <Alert
                    severity="info"
                    variant="outlined"
                    title="Note to admin"
                    body={
                        <p>
                            Plaid Errors only reset after Plaid Balance is
                            Processed - 9AM ET. To avoid any issues, best to do
                            this check right after the Balance script is run. In
                            the future, we should update the Plaid Error after
                            succesful plaid link update via webhook.
                        </p>
                    }
                />
                <SectionTitle>Expired Plaid Items</SectionTitle>
            </div>
            <PlaidItemsTable plaidItems={expiredPlaidItems} />
        </div>
    ) : null;
}

export default ExpiredPlaidItems;
