import React, { useState } from "react";
import HeaderSection from "./HeaderSection";
import CapitalGraphicSvg from "../../assets/CapitalGraphic.svg";
import CapitalProducts from "./CapitalProducts";
import CalendlyModal from "../CalendlyModal";
import { CALENDLY_URLS } from "../CalendlyModal/constants";

function Capital() {
    const [showCalendlyModal, setShowCalendlyModal] = useState(false);

    return (
        <div className="flex flex-col">
            <HeaderSection
                title="Capital For Every Stage Of Your Journey"
                body="At Flychain, we understand that your practice is
                    one-of-a-kind. That's why we believe in working with you to develop a
                    financing strategy that perfectly fits your business model and
                    stage of growth. Whether you're just starting out, gearing
                    up for growth, or looking to sell, we'll help you
                    figure out the best capital strategy moving forward."
                buttonText="Develop a Capital Strategy"
                onClick={() => setShowCalendlyModal(true)}
                graphic={
                    <img
                        className="w-[15vw]"
                        src={CapitalGraphicSvg}
                        alt="CapitalGraphicSvg"
                    />
                }
            />
            <CapitalProducts />
            {showCalendlyModal && (
                <CalendlyModal
                    onClose={() => setShowCalendlyModal(false)}
                    calendlyUrl={CALENDLY_URLS.FLYCHAIN_TEAM}
                />
            )}
        </div>
    );
}

export default Capital;
