import React from "react";
import PropTypes from "prop-types";
import { useGetPlaidAccountMappingsQuery } from "../../../../../redux/slices/adminApi";
import useGetBusinessEntitiesQuery from "../../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import SectionTitle from "../../../../SectionTitle";

const accountNameString = (rawPlaidAccount) =>
    `${rawPlaidAccount.name} - ${rawPlaidAccount.mask}`;

const businessEntityString = (businessEntity) =>
    `${businessEntity.legal_name} - ${businessEntity.id}`;

function AlreadyLinked({ providerId }) {
    // use this to populate the dropdown
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        includeIncomplete: true,
    });
    const { data: plaidAccountMappings } =
        useGetPlaidAccountMappingsQuery(providerId);

    return plaidAccountMappings && businessEntities ? (
        <div className="bg-[#31c4cf]">
            <div>
                <SectionTitle> Already Linked: </SectionTitle>
            </div>
            {businessEntities.map((businessEntity) => (
                <div key={businessEntity.id}>
                    <h3>{businessEntityString(businessEntity)}</h3>
                    {plaidAccountMappings
                        .filter(
                            (mapping) =>
                                mapping.business_entity_id ===
                                    businessEntity.id &&
                                mapping.internal_teal_instance_id
                        )
                        .map((mapping) => (
                            <div key={mapping.id}>
                                {accountNameString(mapping.raw_plaid_account)}
                                {/* Add link button or other UI elements here */}
                            </div>
                        ))}
                </div>
            ))}
        </div>
    ) : null;
}

AlreadyLinked.propTypes = {
    providerId: PropTypes.string.isRequired,
};

export default AlreadyLinked;
