import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../Modal";
import Button from "../../../Button";
import { Header, Subheading } from "../../../Text";

function DeleteUserModal({ user, provider, onClose, onClick }) {
    return (
        <Modal onClose={onClose}>
            <div className="flex flex-col">
                <Header>Confirm user delete</Header>
                <Subheading>{`Please confirm that you want to delete ${user.email} with ID ${user.id}.`}</Subheading>
                {provider && (
                    <>
                        <Subheading>{`This user is associated with the provider, ${provider.legal_name}, with ID ${provider.id}.`}</Subheading>
                        <Subheading>
                            When you delete the user please make sure to delete
                            the provider (if safe) and any other documents
                            related to the provider (e.g. PlaidItems)
                        </Subheading>
                    </>
                )}
                <div className="mt-[20px]">
                    <Button onClick={onClick}>Confirm</Button>
                </div>
            </div>
        </Modal>
    );
}

DeleteUserModal.propTypes = {
    user: PropTypes.object.isRequired,
    provider: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
};

DeleteUserModal.defaultProps = {
    provider: null,
};

export default DeleteUserModal;
