import React from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../PageTitle";
import SectionTitle from "../../../SectionTitle";
import Table from "./Table";

function Mappings() {
    const { providerId } = useParams();
    return (
        <div className="flex flex-col justify-start p-[60px]">
            <div className="mb-20px">
                <PageTitle> Mappings </PageTitle>
            </div>
            <div>
                <SectionTitle> Plaid Accounts </SectionTitle>
            </div>
            <div>
                {" "}
                Please ensure that for each Plaid account, the right business
                entity is linked. Base this off the spreadsheet generated by the
                bookkeepers.{" "}
                <b>
                    {" "}
                    Failure to ensure this step is correct will break future
                    linking.{" "}
                </b>{" "}
                Teal highlight indicates that the account is already Teal linked
                and therefore changes to mapping are not permitted here.
            </div>
            {providerId && <Table providerId={providerId} />}
            {/* a list of all plaid accounts. for each one there is a drop-down allowing a rewiring to business entity. for accounts already linked, changing not possible here } */}
        </div>
    );
}

export default Mappings;
