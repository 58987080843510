/* eslint-disable camelcase */
import React, { useState } from "react";
import dayjs from "dayjs";
import PageTitle from "../../PageTitle";
import ValuationExplanationCard from "./ValuationExplanationCard";
import useGetValuationQuery from "../../../hooks/queriesAndMutations/useGetValuationQuery";
import { getCurrencyString } from "../../../utils";
import NetProfitTable from "./NetProfitTable";
import EBITDATable from "./EBITDATable";
import EBITDAMultiplesModal from "./EBITDAMultiplesModal";
import ClockSvg from "../../../assets/Clock";
import BadgeDollarSignSvg from "../../../assets/BadgeDollarSign";
import CalculatorSvg from "../../../assets/Calculator";
import CirclePlusSvg from "../../../assets/CirclePlus";
import CalendarSvg from "../../../assets/Calendar";
import CopyXSvg from "../../../assets/CopyX";
import TrendingUpSvg from "../../../assets/TrendingUp";
import Button from "../../Button";
import { MERTZ_TAGGART_INTRO_CALL_URL } from "../ValuationEstimate";

function renderValuationRange(isNegativeEbitda, valuation_cents) {
    if (isNegativeEbitda) {
        return (
            <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
                <div className="text-2xl font-bold">
                    No Valuation Range can be provided at this time.
                </div>
                <div className="text-sm font-normal text-flychainGray">
                    Your annualized EBITDA is negative and therefore we
                    aren&apos;t able to provide a Valuation Range at this time.
                    For a full valuation, we recommend scheduling a free
                    valuation with our M&A partner.
                </div>
                <Button
                    onClick={() =>
                        window.open(MERTZ_TAGGART_INTRO_CALL_URL, "_blank")
                    }
                    className="inline-block rounded-full px-[16px] py-[12px] w-fit mt-[10px] bg-flychainPurple-light text-white"
                >
                    Schedule a Free Valuation
                </Button>
            </div>
        );
    }

    return (
        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
            <div className="text-2xl font-bold">
                {`${getCurrencyString(
                    valuation_cents[0]
                )} to ${getCurrencyString(valuation_cents[1])}`}
            </div>
            <div className="text-sm font-normal text-flychainGray">
                Finally, we apply your multiples to the annualized EBITDA to get
                your Valuation Range. For a full valuation, we recommend
                scheduling a free valuation with our M&A partner.
            </div>
            <Button
                onClick={() =>
                    window.open(MERTZ_TAGGART_INTRO_CALL_URL, "_blank")
                }
                className="inline-block rounded-full px-[16px] py-[12px] w-fit mt-[10px] bg-flychainPurple-light text-white"
            >
                Schedule a Free Valuation
            </Button>
        </div>
    );
}

function ValuationExplanation() {
    const { data: valuationData } = useGetValuationQuery();

    if (!valuationData) return null;

    const {
        start_date,
        end_date,
        raw_results,
        total_ebitda_cents,
        annualized_ebitda_cents,
        multiple_range,
        valuation_cents,
    } = valuationData;

    const startDate = dayjs(start_date);
    const endDate = dayjs(end_date);
    const timePeriodString = `${startDate.format(
        "MMMM D, YYYY"
    )} to ${endDate.format("MMMM D, YYYY")}`;

    const isNegativeEbitda = annualized_ebitda_cents < 0;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const onClick = () => setIsModalOpen(true);
    const onClose = () => setIsModalOpen(false);

    const ebitdaMultiplesDescription = (
        <div>
            Your EBITDA multiples are based on market standards and can vary
            based on various factors explained{" "}
            <span
                onClick={onClick}
                className="cursor-pointer text-flychainPurple-light"
            >
                here
            </span>
            . To get more accurate EBITDA multiples, we recommend scheduling a
            free valuation with our M&A partner.
        </div>
    );

    return valuationData ? (
        <div className="flex flex-col p-[60px] bg-white">
            <div className="flex flex-col gap-y-[50px] w-full">
                <div className="flex flex-row justify-center w-full gap-x-[50px] mb-[30px]">
                    <PageTitle className="w-[40%]">
                        How Do We Calculate Your Valuation Range?
                    </PageTitle>
                    <div className="w-[40%] text-base font-normal">
                        The first step to increasing your valuation is
                        understanding how it&apos;s calculated and what factors
                        go into this calculation.
                    </div>
                </div>
                <div className="flex flex-row justify-center w-full gap-x-[50px]">
                    <ValuationExplanationCard
                        icon={
                            <ClockSvg fill="#8D58FF" height={28} width={28} />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-[#fcfbf7]"
                        title="1. Get the time period considered"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
                            <div className="text-2xl font-bold">
                                {timePeriodString}
                            </div>
                            <div className="text-sm font-normal text-flychainGray">
                                If we have twelve months or more of your
                                financials on our platform, we&apos;ll use the
                                trailing twelve months. Otherwise, we&apos;ll
                                use as many months as we have.
                            </div>
                        </div>
                    </ValuationExplanationCard>
                    <ValuationExplanationCard
                        icon={
                            <BadgeDollarSignSvg
                                fill="#8D58FF"
                                height={28}
                                width={28}
                            />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-[#fcfbf7]"
                        title="2. Get the Net Profit for each entity"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[10px] mx-[6px]">
                            <div className="flex flex-col max-h-[120px] overflow-y-auto scrollbar scrollbar-thumb-rounded scrollbar-track-transparent scrollbar-thumb-flychainPurple-light">
                                <NetProfitTable rawResults={raw_results} />
                            </div>
                            <div className="text-sm font-normal text-flychainGray">
                                If you have multiple entities, the net profit
                                for each entity is shown above.
                            </div>
                        </div>
                    </ValuationExplanationCard>
                </div>
                <div className="flex flex-row justify-center w-full gap-x-[50px]">
                    <ValuationExplanationCard
                        icon={
                            <CalculatorSvg
                                fill="#8D58FF"
                                height={28}
                                width={28}
                            />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-[#fcfbf7]"
                        title="3. Calculate EBITDA for each entity"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[10px] mx-[6px]">
                            <div className="flex flex-col max-h-[120px] overflow-y-auto scrollbar scrollbar-thumb-rounded scrollbar-track-transparent scrollbar-thumb-flychainPurple-light">
                                <EBITDATable rawResults={raw_results} />
                            </div>

                            <div className="text-sm font-normal text-flychainGray">
                                EBITDA is calculated by adding back any expenses
                                related to interest, taxes, depreciation, and
                                amortization.
                            </div>
                        </div>
                    </ValuationExplanationCard>
                    <ValuationExplanationCard
                        icon={
                            <CirclePlusSvg
                                fill="#8D58FF"
                                height={28}
                                width={28}
                            />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-[#fcfbf7]"
                        title="4. Sum Up the EBITDA for all entities"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
                            <div className="text-2xl font-bold">
                                {getCurrencyString(total_ebitda_cents)}
                            </div>
                            <div className="text-sm font-normal text-flychainGray">
                                If you have a single entity, you can ignore this
                                step.
                            </div>
                        </div>
                    </ValuationExplanationCard>
                </div>
                <div className="flex flex-row justify-center w-full gap-x-[50px]">
                    <ValuationExplanationCard
                        icon={
                            <CalendarSvg
                                fill="#8D58FF"
                                height={28}
                                width={28}
                            />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-[#fcfbf7]"
                        title="5. Annualize the EBITDA"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
                            <div className="text-2xl font-bold">
                                {getCurrencyString(annualized_ebitda_cents)}
                            </div>
                            <div className="text-sm font-normal text-flychainGray">
                                If the time period considered is less than 12
                                months, the EBITDA is annualized.
                            </div>
                        </div>
                    </ValuationExplanationCard>
                    <ValuationExplanationCard
                        icon={
                            <CopyXSvg fill="#8D58FF" height={28} width={28} />
                        }
                        className="flex flex-col w-[40%] p-[24px] bg-[#fcfbf7]"
                        title="6. Get the EBITDA multiples"
                    >
                        <div className="flex flex-col gap-y-[20px] mt-[15px] mx-[6px]">
                            <div className="text-2xl font-bold">
                                {`${multiple_range[0]}x to ${multiple_range[1]}x`}
                            </div>
                            <div className="text-sm font-normal text-flychainGray">
                                {ebitdaMultiplesDescription}
                            </div>
                        </div>
                    </ValuationExplanationCard>
                </div>
                <div className="flex flex-row justify-center w-full">
                    <ValuationExplanationCard
                        icon={
                            <TrendingUpSvg
                                fill="#8D58FF"
                                height={28}
                                width={28}
                            />
                        }
                        className="flex flex-col w-[calc(80%+50px)] p-[24px] bg-[#eaf2e9]"
                        title="7. Calculate the Valuation Range"
                    >
                        {renderValuationRange(
                            isNegativeEbitda,
                            valuation_cents
                        )}
                    </ValuationExplanationCard>
                </div>
            </div>
            {isModalOpen && <EBITDAMultiplesModal onClose={onClose} />}
        </div>
    ) : null;
}

export default ValuationExplanation;
