import React from "react";
import PropTypes from "prop-types";
import {
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Bar,
    ErrorBar,
    ResponsiveContainer,
} from "recharts";

function ValuationChart({ data }) {
    const domainMax = 1.1 * (data[0].value + data[0].error[1]);

    const verticalGridlines = [
        data[0].value - data[0].error[0],
        data[0].value + data[0].error[1],
    ];

    return (
        <ResponsiveContainer width="100%" height={150}>
            <BarChart
                data={data}
                layout="vertical"
                margin={{ top: 0, right: 0, left: 0, bottom: 20 }}
            >
                <YAxis type="category" tick={false} hide />
                <XAxis
                    dataKey="value"
                    type="number"
                    domain={[0, domainMax]}
                    ticks={verticalGridlines}
                    tickFormatter={(tick) => tick / 100000000}
                    stroke="#666666"
                    axisLine={{ stroke: "#E5E5E5" }}
                    tickLine={{ stroke: "#E5E5E5" }}
                    label={{
                        value: "million ($)",
                        position: "bottom",
                        offset: 0,
                    }}
                />
                <CartesianGrid
                    horizontal={false}
                    verticalPoints={verticalGridlines}
                    strokeDasharray="3 3"
                    stroke="#E5E5E5"
                />
                <Bar
                    dataKey="value"
                    fill="url(#gradient)"
                    barSize={65}
                    background={{ fill: "transparent" }}
                >
                    <ErrorBar
                        dataKey="error"
                        stroke="black"
                        strokeWidth={1}
                        width={15}
                        direction="x"
                    />
                </Bar>
                <defs>
                    <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="0">
                        <stop offset="0%" stopColor="#5F58FF" />
                        <stop offset="100%" stopColor="#8D58FF" />
                    </linearGradient>
                </defs>
            </BarChart>
        </ResponsiveContainer>
    );
}

ValuationChart.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.number.isRequired,
            error: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
        }).isRequired
    ).isRequired,
};

export default ValuationChart;
