import React from "react";
import PropTypes from "prop-types";
import Button from "../../../../Button";
import ToUpdateTable from "./ToUpdateTable";
import COLLECTION_FLOW_STATES from "../states";
import { useLastPaymentCheckQuery } from "../../../../../redux/slices/adminApi";

function LastPaymentCheck({ payments, setPayments, setCollectionFlowState }) {
    const paymentIds = payments.map((payment) => payment.id);
    const { data: lastPaymentData } = useLastPaymentCheckQuery(paymentIds);

    const onClickContinue = () => {
        setCollectionFlowState(COLLECTION_FLOW_STATES.BALANCE_CHECK);
    };

    const onClickBack = () => {
        setCollectionFlowState(COLLECTION_FLOW_STATES.SELECTION);
    };

    return lastPaymentData ? (
        <div>
            <Button onClick={onClickBack}>Back</Button>
            <h1>Last Payment Check</h1>
            {lastPaymentData.length > 0 ? (
                <div>
                    <p>
                        The following payments have been identified as the last
                        payment for their respective draws. Please review the
                        payments below and update the payment amount if
                        necessary.
                    </p>
                    <ToUpdateTable
                        payments={payments}
                        lastPaymentData={lastPaymentData}
                        setPayments={setPayments}
                    />
                </div>
            ) : (
                <p> No last payments detected. Please proceed. </p>
            )}
            <div className="fixed bottom-[20px] left-1/2">
                <Button onClick={onClickContinue}>
                    Next
                    {/* TODO: disable next button until all last payments have been updated */}
                </Button>
            </div>
        </div>
    ) : null;
}

LastPaymentCheck.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setPayments: PropTypes.func.isRequired,
    setCollectionFlowState: PropTypes.func.isRequired,
};

export default LastPaymentCheck;
