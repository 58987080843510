import React from "react";
import HeaderSection from "../HeaderSection";
import ProductSummary, { productSummaryTitles } from "../ProductSummary";
import QualificationRequirements, {
    qualificationRequirementsTitles,
} from "../QualificationRequirements";
import SBAGraphicSvg from "../../../assets/SBAGraphic.svg";

const productSummaryRows = [
    [
        productSummaryTitles.USE_OF_FUNDS,
        "Working capital, business acquisition, equipment purchase, inventory purchase, refinance of eligible business debt, leasehold improvements",
    ],
    [productSummaryTitles.LOAN_AMOUNT, "$25,000 to $5,000,000"],
    [
        productSummaryTitles.TIME_TO_FUND,
        "Funding as soon as 10 business days. Funding as soon as 5 business days for loans less than $150,000.00 (SBA Express program).",
    ],
    [productSummaryTitles.REPAYMENT_PERIOD, "5-10 years"],
    [productSummaryTitles.INTEREST_RATE, "WSJ Prime +4.50%"],
];

const qualificationRequirementsRows = [
    [qualificationRequirementsTitles.TIME_IN_BUSINESS, "At least 2 years"],
    [qualificationRequirementsTitles.FICO, "At least 680"],
];

function SBAPrograms() {
    const onClick = () => {
        window.open("https://lendistry.com/partner/flychain-inc/", "_blank");
    };
    return (
        <div className="flex flex-col">
            <HeaderSection
                title="SBA Programs"
                body="SBA loans have some of the best terms in the market with competitive rates and a 5-10 year repayment period. We've designed a simple and streamlined process with a support team to hold your hand through every step from initial application to funding."
                buttonText="Apply Now"
                onClick={onClick}
                graphic={
                    <img
                        className="h-[30vh]"
                        src={SBAGraphicSvg}
                        alt="SBAGraphicSvg"
                    />
                }
            />
            <div className="flex flex-col p-[60px] gap-y-[50px]">
                <ProductSummary rows={productSummaryRows} />
                <QualificationRequirements
                    rows={qualificationRequirementsRows}
                />
            </div>
        </div>
    );
}

export default SBAPrograms;
