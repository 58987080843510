import React from "react";
import PropTypes from "prop-types";
import {
    useGetTealInstancesQuery,
    useCreateTealInstanceMutation,
} from "../../../../../redux/slices/adminApi";
import useGetBusinessEntitiesQuery from "../../../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";
import Button from "../../../../Button";

const tealInstanceForEntity = (tealInstances, businessEntityId) => {
    const foundInstance = tealInstances.find(
        (tealInstance) => tealInstance.business_entity_id === businessEntityId
    );
    return foundInstance;
    // replace with a button to make a new teal instance
};

// EntitiesTable
function Table({ providerId }) {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        providerId,
        includeIncomplete: true,
    });
    const { data: tealInstances } = useGetTealInstancesQuery(providerId);

    const [createTealInstance] = useCreateTealInstanceMutation();

    const onClick = (businessEntityId) => {
        createTealInstance({
            providerId,
            body: { business_entity_id: businessEntityId },
        });
    };
    // pull in teal instance data here for each entity and then display
    return businessEntities && tealInstances ? (
        <div className="flex flex-col justify-start">
            <div className="my-[30px]">
                <table className="border-separate border-spacing-x-[16px] border-spacing-y-[32px]">
                    <thead>
                        <tr>
                            <th>
                                <div>Business Entity ID</div>
                            </th>
                            <th>
                                <div>Business Entity Legal Name</div>
                            </th>
                            <th>
                                <div>Internal Teal Instance ID</div>
                            </th>
                            <th>
                                <div>External Teal Instance ID</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {businessEntities.map((businessEntity) => {
                            const tealInstance = tealInstanceForEntity(
                                tealInstances,
                                businessEntity.id
                            );
                            return (
                                <tr key={businessEntity.id}>
                                    <td>
                                        <div>{businessEntity.id}</div>
                                    </td>
                                    <td>
                                        <div>{businessEntity.legal_name}</div>
                                    </td>
                                    <td>
                                        {tealInstance ? (
                                            <div>{tealInstance.id}</div>
                                        ) : (
                                            <Button
                                                onClick={() =>
                                                    onClick(businessEntity.id)
                                                }
                                            >
                                                Create Instance
                                            </Button>
                                        )}
                                    </td>
                                    <td>
                                        {tealInstance ? (
                                            <div>
                                                {
                                                    tealInstance.external_teal_instance_id
                                                }
                                            </div>
                                        ) : (
                                            <div>Not Created</div>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    ) : null;
}

Table.propTypes = {
    providerId: PropTypes.string.isRequired,
};

export default Table;
