import React from "react";

function NoProvidersFound() {
    return (
        <div className="flex flex-col justify-center items-center h-full pb-[30px] pr-[60px]">
            <div className="text-[24px] font-semibold">No providers found</div>
        </div>
    );
}

export default NoProvidersFound;
