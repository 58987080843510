/* eslint-disable camelcase */
import React, { useState } from "react";
import PageTitle from "../../../PageTitle";
import Line from "./Line";
import Button from "../../../Button";
import DrawsTable from "./DrawsTable";
import RequestFundingModal from "./RequestFundingModal";
import DrawModal from "./DrawModal";
import SectionTitle from "../../../SectionTitle";
import useGetDataForAdvancedPaymentOnClaimsQuery from "../../../../hooks/queriesAndMutations/useGetDataForAdvancedPaymentOnClaimsQuery";

function LiveAdvancedPaymentOnClaims() {
    const { data: advancedPaymentOnClaimsData } =
        useGetDataForAdvancedPaymentOnClaimsQuery();

    const [showRequestFundingModal, setShowRequestFundingModal] =
        useState(false);

    const requestFundingButtonOnClick = () => {
        setShowRequestFundingModal(true);
    };

    const requestFundingModalOnClose = () => {
        setShowRequestFundingModal(false);
    };

    const [showDrawModal, setShowDrawModal] = useState(false);

    const [drawIndex, setDrawIndex] = useState(null);

    const drawsTableOnClick = (i) => {
        setDrawIndex(i);
        setShowDrawModal(true);
    };

    const drawModalOnClose = () => {
        setShowDrawModal(false);
    };

    if (!advancedPaymentOnClaimsData) return null;

    const {
        line_outstanding_balance_cents,
        line_available_balance_cents,
        draws_for_display,
    } = advancedPaymentOnClaimsData;

    return (
        <div className="flex flex-col justify-start p-[60px]">
            <PageTitle className="mb-[50px]">
                Advanced Payment on Claims
            </PageTitle>
            <Line
                outstandingBalanceCents={line_outstanding_balance_cents}
                availableBalanceCents={line_available_balance_cents}
            />
            <div className="mt-[40px]">
                <Button
                    onClick={requestFundingButtonOnClick}
                    disabled={line_available_balance_cents === 0}
                >
                    Request funding
                </Button>
            </div>
            {showRequestFundingModal && (
                <RequestFundingModal
                    onClose={requestFundingModalOnClose}
                    availableBalanceCents={line_available_balance_cents}
                />
            )}
            <div className="flex flex-col mt-[100px] gap-y-[30px]">
                <SectionTitle>Your draws</SectionTitle>
                <DrawsTable
                    draws={draws_for_display}
                    onClick={drawsTableOnClick}
                    includeSeeRepaymentsColumn
                />
            </div>
            {showDrawModal && (
                <DrawModal
                    draw={draws_for_display[drawIndex]}
                    onClose={drawModalOnClose}
                />
            )}
        </div>
    );
}

export default LiveAdvancedPaymentOnClaims;
