import React from "react";
import PropTypes from "prop-types";
import { getCurrencyString } from "../../../../../../../utils";
import SectionTitle from "../../../../../../SectionTitle";

function DrawsTable({ draws }) {
    return (
        <div className="flex flex-col justify-start">
            <div className="my-[30px]">
                <SectionTitle>Draws</SectionTitle>
                <table className="border-separate border-spacing-x-[16px] border-spacing-y-[32px]">
                    <thead>
                        <tr>
                            <th>Draw ID</th>
                            <th>Draw Fee</th>
                            <th>ACH Sent Date</th>
                            <th>Principal</th>
                        </tr>
                    </thead>
                    <tbody>
                        {draws.map((draw) => (
                            <tr key={draw.id}>
                                <td>{draw.id}</td>
                                <td>{getCurrencyString(draw.fee)}</td>
                                <td>{draw.ach_sent_date}</td>
                                <td>{getCurrencyString(draw.principal)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

DrawsTable.propTypes = {
    draws: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default DrawsTable;
