import React from "react";
import PropTypes from "prop-types";

function CirclePlusSvg({ fill, height, width }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <circle cx="12" cy="12" r="10" />
            <path d="M8 12h8" />
            <path d="M12 8v8" />
        </svg>
    );
}

CirclePlusSvg.propTypes = {
    fill: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
};

CirclePlusSvg.defaultProps = {
    height: undefined,
    width: undefined,
};

export default CirclePlusSvg;
