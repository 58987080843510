import React from "react";
import PropTypes from "prop-types";

function Text({ children }) {
    return (
        <div className="font-sans text-sm font-light text-left text-black">
            {children}
        </div>
    );
}

Text.propTypes = {
    children: PropTypes.node.isRequired,
};

export function Header({ children }) {
    return (
        <Text>
            <div className="mt-[20px] mb-[20px] text-xl font-medium">
                {children}
            </div>
        </Text>
    );
}

Header.propTypes = {
    children: PropTypes.node.isRequired,
};

export function Subheading({ children }) {
    return (
        <Text>
            <div className="mt-[10px] mb-[10px] text-lg font-light text-flychainGray">
                {children}
            </div>
        </Text>
    );
}

Subheading.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Text;
