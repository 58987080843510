import React from "react";
import PropTypes from "prop-types";
import COLLECTION_FLOW_STATES from "../states";
import Button from "../../../../Button";
import SelectionTable from "./SelectionTable";

const paymentsBelongToOneProvider = (payments) =>
    payments.every(
        (payment) => payment.provider_id === payments[0].provider_id
    );

function Selection({ payments, setPayments, setCollectionFlowState }) {
    const onClick = () => {
        setCollectionFlowState(COLLECTION_FLOW_STATES.LAST_PAYMENT_CHECK);
    };
    return (
        <div className="p-[60px]">
            <SelectionTable payments={payments} setPayments={setPayments} />
            <div className="p-[60px]">
                <Button
                    onClick={onClick}
                    disabled={
                        payments.length === 0 ||
                        !paymentsBelongToOneProvider(payments)
                    }
                >
                    Proceed to Collection
                </Button>
            </div>
        </div>
    );
}

Selection.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setPayments: PropTypes.func.isRequired,
    setCollectionFlowState: PropTypes.func.isRequired,
};

export default Selection;
