import React, { useState } from "react";
import PropTypes from "prop-types";
import COLLECTION_FLOW_STATES from "../states";
import Button from "../../../../Button";
import CollectionTable from "./CollectionTable";
import CollectPaymentLog from "./CollectPaymentLog";
import PageTitle from "../../../../PageTitle";

function Collection({ payments, setCollectionFlowState }) {
    // TODO: add note to not reload the page

    const [displayedResponse, setDisplayedResponse] = useState({
        message: "No api response yet",
    });

    const [collectedPaymentIds, setCollectedPaymentIds] = useState([]);

    const allPaymentsCollected = payments.every((payment) =>
        collectedPaymentIds.includes(payment.id)
    );

    const onClickContinue = () => {
        setCollectionFlowState(COLLECTION_FLOW_STATES.POST_COLLECTION);
    };

    const onClickBack = () => {
        setCollectionFlowState(COLLECTION_FLOW_STATES.BALANCE_CHECK);
    };
    return (
        <div className="p-[60px]">
            <div className="fixed top-[20px]">
                <Button onClick={onClickBack}>Back</Button>
            </div>
            <CollectPaymentLog displayedResponse={displayedResponse} />
            <PageTitle>Collections</PageTitle>
            <CollectionTable
                payments={payments}
                setDisplayedResponse={setDisplayedResponse}
                collectedPaymentIds={collectedPaymentIds}
                setCollectedPaymentIds={setCollectedPaymentIds}
            />
            <div className="fixed bottom-[20px] left-1/2">
                <Button
                    onClick={onClickContinue}
                    disabled={!allPaymentsCollected}
                >
                    Next
                </Button>
            </div>
        </div>
    );
}

Collection.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setCollectionFlowState: PropTypes.func.isRequired,
};

export default Collection;
