import React from "react";
import useGetProviderQuery from "../../../../hooks/queriesAndMutations/useGetProviderQuery";
import PageTitle from "../../../PageTitle";

function SelectedProvider() {
    const { data: provider } = useGetProviderQuery();
    return provider ? (
        <div className="flex flex-col justify-center text-center py-[20px] bg-[#f2f2f2]">
            <div className="text-base font-normal text-flychainGray">
                Currently Selected Provider
            </div>
            <PageTitle>{provider.legal_name}</PageTitle>
        </div>
    ) : null;
}

export default SelectedProvider;
