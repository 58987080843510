import { THEME, THEMES } from "../../index";

const getEmail = () => {
    switch (THEME) {
        case THEMES.FLYCHAIN:
            return "jaime@flychain.us";
        default:
            throw new Error("Missing case for theme");
    }
};

const EMAIL = getEmail();

export default EMAIL;
