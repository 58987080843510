import React from "react";
import PropTypes from "prop-types";
import {
    useGetFinancialReportsValidUpUntilQuery,
    useGetEntriesStartQuery,
} from "../../../../../redux/slices/adminApi";
import UpdateValidUpUntil from "./UpdateValidUpUntil";
import UpdateEntriesStart from "./UpdateEntriesStart";

function BusinessEntity({ businessEntity }) {
    const { data: validUpUntilData } = useGetFinancialReportsValidUpUntilQuery({
        providerId: businessEntity.provider_id,
        businessEntityId: businessEntity.id,
    });
    const { data: entriesStartData } = useGetEntriesStartQuery({
        providerId: businessEntity.provider_id,
        businessEntityId: businessEntity.id,
    });

    return (
        <div className="flex flex-row justify-between items-center h-fit pt-[2px] gap-x-[10px]">
            <div className="flex flex-col h-fit mb-[3px] grow">
                <div className="text-[16px]">{businessEntity.legal_name}</div>
                <div className="text-sm text-flychainGray">
                    EIN: {businessEntity.ein}
                </div>
            </div>
            <div className="flex flex-row gap-x-[15px] items-center h-fit">
                {entriesStartData ? (
                    <UpdateEntriesStart
                        entriesStart={entriesStartData.entries_start}
                        businessEntity={businessEntity}
                    />
                ) : null}
                {validUpUntilData ? (
                    <UpdateValidUpUntil
                        validUpUntil={validUpUntilData.valid_up_until}
                        businessEntity={businessEntity}
                    />
                ) : null}
            </div>
        </div>
    );
}

BusinessEntity.propTypes = {
    businessEntity: PropTypes.object.isRequired,
};

export default BusinessEntity;
