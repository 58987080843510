import firebase from "firebase/compat/app";
import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const prepareHeaders = (idToken) => (headers) => {
    headers.set("authorization", `Bearer ${idToken}`);
    return headers;
};

const createBaseQuery = (isAdmin) => {
    const baseUrl = isAdmin
        ? `${process.env.REACT_APP_API_URL}/admin`
        : `${process.env.REACT_APP_API_URL}/api`;

    return async (args, api, extraOptions) => {
        const idToken = await firebase.auth().currentUser.getIdToken();

        const fetchQuery = fetchBaseQuery({
            baseUrl,
            prepareHeaders: prepareHeaders(idToken),
        });

        return fetchQuery(args, api, extraOptions);
    };
};

export default createBaseQuery;
