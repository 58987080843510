import React from "react";
import PropTypes from "prop-types";

function CollectPaymentLog({ displayedResponse }) {
    return (
        <div className="fixed top-0 right-0 mt-[20px] mr-[20px] w-[60%] bg-white border-2 border-black rounded p-[10px]">
            <h3>Collect Payment Response:</h3>
            <p>{JSON.stringify(displayedResponse)}</p>
        </div>
    );
}

CollectPaymentLog.propTypes = {
    displayedResponse: PropTypes.object.isRequired,
};

export default CollectPaymentLog;
