import React from "react";
import PageTitle from "../PageTitle";
import HorizontalTabs, { useGetTabIndex } from "../HorizontalTabs";
import BusinessEntityOverview from "../BusinessEntity/BusinessEntityOverview";
import Connections from "../FinancialReports/Connections";

// An array of the tabs to be displayed
const TABS = [
    { key: "connections", label: "Connections" },
    { key: "businessEntities", label: "Business Entities" },
];

// Render the content for the active tab
function renderSettingsContent(currentTabIndex) {
    switch (currentTabIndex) {
        case 0:
            return <Connections />;
        case 1:
            return <BusinessEntityOverview />;
        default:
            return <Connections />;
    }
}

function Settings() {
    const { currentTabIndex, setSearchParams } = useGetTabIndex(TABS);

    // Tab change handler to be passed to the HorizontalTabs component
    const handleTabChange = (index) => {
        setSearchParams({ tab: TABS[index].key }, { replace: true });
    };

    return (
        <div className="flex flex-col p-[60px] gap-y-[20px]">
            <PageTitle>Settings</PageTitle>
            <HorizontalTabs
                tabs={TABS.map((tab) => tab.label)}
                initialIndex={currentTabIndex}
                onChange={handleTabChange}
                className="mb-[25px]"
            />
            {renderSettingsContent(currentTabIndex)}
        </div>
    );
}

export default Settings;
