import { useParams } from "react-router-dom";
import { useGetBusinessEntitiesQuery as useAdminGetBusinessEntitiesQuery } from "../../../redux/slices/adminApi";
import { useGetBusinessEntitiesQuery as useProviderGetBusinessEntitiesQuery } from "../../../redux/slices/api";

const useGetBusinessEntitiesQuery = (params) => {
    // Get the includeIncomplete flag from the function params
    const includeIncomplete = params ? params.includeIncomplete : false;

    // Get the providerId from the params
    let providerId = params ? params.providerId : null;

    const urlParams = useParams();

    // If providerId is not provided in function params, try to get it from the url params
    if (!providerId) {
        providerId = urlParams.providerId || null;
    }

    // If providerId is provided via function params or url params, use the admin query
    if (providerId) {
        return useAdminGetBusinessEntitiesQuery({
            providerId,
            includeIncomplete,
        });
    }

    // If providerId is not provided at all, use the provider query. Provider id will be passed in via the request's user context
    return useProviderGetBusinessEntitiesQuery({
        includeIncomplete,
    });
};

export default useGetBusinessEntitiesQuery;
