import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@mui/material/Checkbox";
import {
    useGetProvidersQuery,
    useGetTasksQuery,
} from "../../../../../../redux/slices/adminApi";
import { getCurrencyString } from "../../../../../../utils";
import SectionTitle from "../../../../../SectionTitle";

function SelectionTable({ payments, setPayments }) {
    const { data: tasks } = useGetTasksQuery();
    const { data: providerIdToProvider } = useGetProvidersQuery();

    const toggleCheckbox = (payment) => {
        if (payments.includes(payment)) {
            // remove payment from payments
            setPayments(payments.filter((p) => p !== payment));
        } else {
            // add payment to payments
            setPayments([...payments, payment]);
        }
    };

    return tasks && providerIdToProvider ? (
        <div className="flex flex-col justify-start">
            <div className="flex flex-col gap-y-[20px] my-[30px]">
                <SectionTitle>Uncollected Payments</SectionTitle>
                <table className="border-separate border-spacing-x-[16px] border-spacing-y-[32px]">
                    <thead>
                        <tr>
                            <th>
                                <div>Payment ID</div>
                            </th>
                            <th>
                                <div>Provider name</div>
                            </th>
                            <th>
                                <div>Provider ID</div>
                            </th>
                            <th>
                                <div>Payment amount</div>
                            </th>
                            <th>
                                <div>Earliest payment date</div>
                            </th>
                            <th>
                                <div>Draw ID</div>
                            </th>
                            <th>
                                <div>Select for Collection</div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.uncollected_payments.map((payment) => (
                            <tr key={payment.id}>
                                <td>
                                    <div>{payment.id}</div>
                                </td>
                                <td>
                                    <div>
                                        {
                                            providerIdToProvider[
                                                payment.provider_id
                                            ].legal_name
                                        }
                                    </div>
                                </td>
                                <td>
                                    <div>{payment.provider_id}</div>
                                </td>
                                <td>
                                    <div>
                                        {getCurrencyString(
                                            payment.payment_amount
                                        )}
                                    </div>
                                </td>
                                <td>
                                    <div>{payment.earliest_payment_date}</div>
                                </td>
                                <td>
                                    <div>{payment.draw_id}</div>
                                </td>
                                <td>
                                    <Checkbox
                                        checked={payments.includes(payment)}
                                        onChange={() => toggleCheckbox(payment)}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    ) : null;
}

SelectionTable.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    setPayments: PropTypes.func.isRequired,
};

export default SelectionTable;
