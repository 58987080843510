import React from "react";
import PropTypes from "prop-types";

function CopyXSvg({ fill, height, width }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 24 24"
            fill="none"
            stroke={fill}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="12" x2="18" y1="12" y2="18" />
            <line x1="12" x2="18" y1="18" y2="12" />
            <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
            <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
        </svg>
    );
}

CopyXSvg.propTypes = {
    fill: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
};

CopyXSvg.defaultProps = {
    height: undefined,
    width: undefined,
};

export default CopyXSvg;
