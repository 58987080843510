import React from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../PageTitle";
import ReadyToLink from "./ReadyToLink";
import AlreadyLinked from "./AlreadyLinked";

function Linking() {
    const { providerId } = useParams();
    return (
        <div className="flex flex-col justify-start p-[60px] gap-y-[20px]">
            <PageTitle> Linking </PageTitle>
            <div>
                {providerId && <AlreadyLinked providerId={providerId} />}
                {providerId && <ReadyToLink providerId={providerId} />}
            </div>
        </div>
    );
}

export default Linking;
