import React from "react";
import PropTypes from "prop-types";
import Text from "../Text";

function PlaidAccount({ account }) {
    const { name, mask, type } = account;

    return (
        <div className="flex flex-col p-[10px] border-[1px] rounded-xl shadow-lg">
            <div className="flex flex-row">
                <Text>{name}</Text>
                {mask && (
                    <div className="ml-[30px]">
                        <Text>{`(••••${mask})`}</Text>
                    </div>
                )}
            </div>
            <Text>
                <div className="text-sm text-flychainGray">
                    {type.toUpperCase()}
                </div>
            </Text>
        </div>
    );
}

PlaidAccount.propTypes = {
    account: PropTypes.object.isRequired,
};

export default PlaidAccount;
