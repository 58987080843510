import React from "react";
import PropTypes from "prop-types";
import SharedCard from "../../../Card";

function ValuationExplanationCard({ icon, title, children, className }) {
    return (
        <SharedCard showTab={false} className={className}>
            <div className="flex flex-row justify-between text-xl font-semibold text-flychainGray ml-[6px] gap-x-[10px]">
                {title}
                {icon}
            </div>
            {children}
        </SharedCard>
    );
}

ValuationExplanationCard.propTypes = {
    icon: PropTypes.element,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

ValuationExplanationCard.defaultProps = {
    icon: undefined,
    className: "",
};

export default ValuationExplanationCard;
