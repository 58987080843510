import React from "react";
import Button from "../../Button";
import Signup from "./Signup";

function SignupAndScheduleDemo() {
    const onClick = () => {};

    const href = `mailto:flychain@mercury.com?cc=jaime@flychain.us&subject=${encodeURIComponent(
        "Scheduling a Mercury Demo"
    )}&body=${encodeURIComponent(
        "Hello,\n\nI would like to schedule a demo of the Mercury platform at your earliest convenience.\n\nThank you!"
    )}`;

    return (
        <div className="flex flex-row items-center">
            <div className="flex-1">
                <Signup />
            </div>
            <div className="ml-[15px]">
                <a href={href}>
                    <Button color="secondary" onClick={onClick}>
                        Schedule Demo
                    </Button>
                </a>
            </div>
        </div>
    );
}

export default SignupAndScheduleDemo;
