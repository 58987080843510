import React from "react";
import PropTypes from "prop-types";

function Table({ headers, rows, boldRows }) {
    return (
        <table className="w-full border-collapse">
            <thead>
                <tr className="border-b border-[#f2f2f2]">
                    {headers.map((header, headerIndex) => (
                        <th
                            key={headerIndex}
                            className="py-[15px] pr-[15px] text-left"
                        >
                            <div className="text-[#757575] text-xs font-semibold">
                                {header}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {rows.map((row, rowIndex) => (
                    <tr key={rowIndex} className="border-b border-[#f2f2f2]">
                        {row.map((cell, cellIndex) => (
                            <td
                                key={cellIndex}
                                className="py-[15px] pr-[15px] text-left"
                            >
                                <div
                                    className={`text-[12px]  ${
                                        boldRows
                                            ? "font-semibold"
                                            : "font-normal"
                                    }`}
                                >
                                    {cell}
                                </div>
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

Table.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    rows: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.node.isRequired).isRequired
    ).isRequired,
    boldRows: PropTypes.bool,
};

Table.defaultProps = {
    boldRows: false,
};

export default Table;
