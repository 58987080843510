import React from "react";
import PropTypes from "prop-types";

function Card({ imageSrc, title, body }) {
    return (
        <div className="flex flex-col p-[25px] bg-[#eae5f2] rounded-[12px]">
            <img src={imageSrc} alt={title} className="mb-[20px]" />
            <div className="text-[20px] font-medium mb-[10px] [&_sup]:text-[12.5px] [&_sup]:font-normal">
                {title}
            </div>
            <div className="text-[14px] font-normal text-flychainGray">
                {body}
            </div>
        </div>
    );
}

Card.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    body: PropTypes.string.isRequired,
};

export default Card;
