import React from "react";
import PropTypes from "prop-types";
import SharedCard from "../../Card";

function TaxBenefitsCard({ title, body }) {
    return (
        <SharedCard showTab={false} className="flex flex-col w-1/3">
            <div className="mb-4 text-xl font-semibold text-center">
                {title}
            </div>
            <div className="text-sm font-normal text-center text-flychainGray">
                {body}
            </div>
        </SharedCard>
    );
}

TaxBenefitsCard.propTypes = {
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
};

export default TaxBenefitsCard;
