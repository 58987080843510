/* eslint-disable camelcase */
import React from "react";
import dayjs from "dayjs";
import ValuationChart from "../../ValuationChart";
import useGetValuationQuery from "../../../../hooks/queriesAndMutations/useGetValuationQuery";
import { getCurrencyString } from "../../../../utils";
import isSufficientData from "./utils";

// # TODO: this is a temporary fix to deal with the Westfork error that occured
//     # when we added a new instance where the bookkeeping dates were set to their initial state. this messes up the max and min dates.
//     # this makes start_date > end_date which breaks the teal call and renders valuation impossible
//     # this will be fixed by the migration to business entity where we will calculate each business entity's valuations separately (so their dates will be separate as well)
//     it goes alongside the change in https://github.com/flychain-us/flychain-backend/pull/514/files#diff-96767711fd61909c804466fd79e4824808472dff42669ea50a38e93b87d91e62R23
const isInvalidDateRange = (startDate, endDate) =>
    dayjs(startDate).isAfter(dayjs(endDate));

const displayContext = (
    isDataSufficient,
    startDate,
    endDate,
    isNegativeEbitda
) => {
    if (!isDataSufficient) {
        const invalidDateRange = isInvalidDateRange(startDate, endDate);
        return (
            <div className="text-xs font-normal">
                We need at least six months of financial data on your practice
                to calculate a Valuation Range
                {!invalidDateRange &&
                    ` - we currently have data for ${dayjs(startDate).format(
                        "MMMM D, YYYY"
                    )} to ${dayjs(endDate).format("MMMM D, YYYY")}`}
                . With less than six months of data, factors such as seasonality
                and one-time expenses can skew results. For a full valuation, we
                recommend scheduling a free valuation with our M&A partner.
            </div>
        );
    }

    if (isNegativeEbitda) {
        return (
            <div className="text-xs font-normal">
                Your annualized EBITDA is negative and therefore we aren&apos;t
                able to provide a Valuation Range at this time. For a full
                valuation, we recommend scheduling a free valuation with our M&A
                partner. To see how we calculated your annualized EBITDA, please
                refer to the section below.
            </div>
        );
    }

    return (
        <div className="text-xs font-normal">
            Date Range Considered: {dayjs(startDate).format("MMMM D, YYYY")} to{" "}
            {dayjs(endDate).format("MMMM D, YYYY")}
        </div>
    );
};

function ValuationRange() {
    const { data: valuationData } = useGetValuationQuery();

    if (!valuationData) return null;

    const { annualized_ebitda_cents, valuation_cents, start_date, end_date } =
        valuationData;

    const isDataSufficient = isSufficientData(start_date, end_date);

    const isNegativeEbitda = annualized_ebitda_cents < 0;

    const shouldBlurValuationRange = !isDataSufficient || isNegativeEbitda;

    const meanValuation = (valuation_cents[0] + valuation_cents[1]) / 2; // divide by 2 for the mean

    return (
        <>
            <div className="rounded-xl shadow-lg border-[1px] p-0">
                <div
                    className={`flex flex-col w-full h-fit gap-y-[10px] ${
                        !shouldBlurValuationRange && "pb-[12px]"
                    }`}
                >
                    <div
                        className={`flex flex-col ${
                            shouldBlurValuationRange
                                ? "p-[24px] gap-y-[40px]"
                                : "px-[24px] pt-[24px] gap-y-[10px]"
                        }`}
                    >
                        <div className="text-[15px">Valuation Range</div>
                        <div className={shouldBlurValuationRange && "blur-lg"}>
                            <div className="flex flex-row items-end gap-x-[6px] flex-wrap">
                                <span className="text-2xl font-bold">
                                    {getCurrencyString(valuation_cents[0])}
                                </span>
                                <span className="text-lg font-bold">to</span>
                                <span className="text-2xl font-bold">
                                    {getCurrencyString(valuation_cents[1])}
                                </span>
                            </div>
                        </div>
                        {displayContext(
                            isDataSufficient,
                            start_date,
                            end_date,
                            isNegativeEbitda
                        )}
                    </div>
                    {!shouldBlurValuationRange && (
                        <ValuationChart
                            data={[
                                {
                                    value: meanValuation,
                                    error: [
                                        meanValuation - valuation_cents[0],
                                        valuation_cents[1] - meanValuation,
                                    ],
                                },
                            ]}
                        />
                    )}
                </div>
            </div>
            {!shouldBlurValuationRange && (
                <div className="font-normal text-[10px] text-flychainGray mt-[20px]">
                    * The valuation range provided above is intended for general
                    informational purposes only and should not be considered as
                    financial, investment, or legal advice. Flychain assumes no
                    responsibility for any decisions made based on this
                    valuation and disclaims all liability in connection with its
                    use.
                </div>
            )}
        </>
    );
}

export default ValuationRange;
