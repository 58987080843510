import React, { useState } from "react";
import PropTypes from "prop-types";
import PostCollectionTable from "./PostCollectionTable";
import Crosscheck from "./Crosscheck";
import FinanceReport from "./FinanceReport";
import Button from "../../../../Button";
import { useGetProviderQuery } from "../../../../../redux/slices/adminApi";

function PostCollection({ payments }) {
    const providerId = payments[0].provider_id;
    const { data: provider } = useGetProviderQuery(providerId);
    const [canEndCollection, setCanEndCollection] = useState(false);

    return provider ? (
        <div className="p-[60px]">
            <Crosscheck
                providerId={providerId}
                setCanEndCollection={setCanEndCollection}
            />
            <PostCollectionTable
                payments={payments}
                tableTitle="Today's Collection"
            />
            <FinanceReport provider={provider} />
            <div
                className="fixed top-0 right-0 mt-[20px] mr-[20px] p-[10px] w-[60%] 
           bg-white border-2 border-black rounded"
            >
                <h2> REMINDER! EMAIL </h2>
                <p>
                    {" "}
                    IMPORTANT! Please email{" "}
                    {provider ? (
                        <b> {provider.contact_email} </b>
                    ) : (
                        "the provider"
                    )}
                    {" with the finance report details above"}
                </p>
            </div>
            <div className="fixed bottom-[20px] left-1/2">
                <Button
                    onClick={() => window.location.reload()}
                    disabled={!canEndCollection}
                >
                    End Collection
                </Button>
            </div>
        </div>
    ) : null;
}

PostCollection.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};

export default PostCollection;
