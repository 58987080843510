import React, { useState } from "react";
import Text, { Subheading, Header } from "../../Text";
import Button from "../../Button";
import {
    useGetProvidersQuery,
    useGetTestUsersQuery,
    useDeleteUserMutation,
} from "../../../redux/slices/adminApi";
import DeleteUserModal from "./DeleteUserModal";

function TestUsers() {
    const { data: testUsers } = useGetTestUsersQuery();

    const { data: providerIdToProvider } = useGetProvidersQuery();

    const [deleteUser] = useDeleteUserMutation();

    const [provider, setProvider] = useState(null);
    const [user, setUser] = useState(null);

    const onClickForModal = () => {
        deleteUser(user.id);
        setUser(null);
        setProvider(null);
    };

    const onCloseForModal = () => {
        setUser(null);
        setProvider(null);
    };

    return testUsers && providerIdToProvider ? (
        <div>
            <Header>Test Users</Header>
            <Subheading>You can delete any of the users below</Subheading>
            <div className="my-[30px]">
                <table className="border-separate border-spacing-x-[16px] border-spacing-y-[32px]">
                    <thead>
                        <tr>
                            <th>
                                <Text>Email</Text>
                            </th>
                            <th>
                                <Text>ID</Text>
                            </th>
                            <th>
                                <Text>Provider Name</Text>
                            </th>
                            <th>
                                <Text>Provider DBA</Text>
                            </th>
                            <th>
                                <Text>Provider ID</Text>
                            </th>
                            <th>
                                <Text>Delete User?</Text>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {testUsers.map((testUser) => {
                            const providerForTestUser = testUser.provider_id
                                ? providerIdToProvider[testUser.provider_id]
                                : null;

                            const onClick = () => {
                                setUser(testUser);
                                if (providerForTestUser) {
                                    setProvider(providerForTestUser);
                                } else {
                                    setProvider(null);
                                }
                            };
                            return (
                                <tr key={testUser.id}>
                                    <td>
                                        <Text>{testUser.email}</Text>
                                    </td>
                                    <td>
                                        <Text>{testUser.id}</Text>
                                    </td>
                                    <td>
                                        <Text>
                                            {providerForTestUser
                                                ? providerForTestUser.legal_name
                                                : ""}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text>
                                            {providerForTestUser
                                                ? providerForTestUser.dba || ""
                                                : ""}
                                        </Text>
                                    </td>
                                    <td>
                                        <Text>
                                            {providerForTestUser
                                                ? providerForTestUser.id
                                                : ""}
                                        </Text>
                                    </td>
                                    <td>
                                        <Button onClick={onClick}>
                                            Delete user
                                        </Button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            {user && (
                <DeleteUserModal
                    user={user}
                    provider={provider}
                    onClick={onClickForModal}
                    onClose={onCloseForModal}
                />
            )}
        </div>
    ) : null;
}

export default TestUsers;
