import React from "react";
import PageTitle from "../../../PageTitle";

function Preconditions() {
    // placeholder for now
    return (
        <div className="flex flex-col justify-start p-[60px] gap-y-[20px]">
            <PageTitle> Preconditions </PageTitle>
            <div className="flex flex-col gap-y-[30px]">
                <div className="font-light text-[15px]">
                    {" "}
                    Before continuing the onboarding process please ensure the
                    following are complete:
                </div>
                <div className="font-light text-[15px]">
                    {" "}
                    1. Discovery call with bookkeeper. Please have a copy of the
                    spreadsheet listing out all entities, accounts and their
                    relationships before proceeding.
                </div>
                {/* one day can have google drive wired up here */}
                <div className="font-light text-[15px]">
                    {" "}
                    2. All expected plaid accounts are connected:
                </div>
                {/* add display of plaid items */}
                <div className="font-light text-[15px]">
                    {" "}
                    If any are missing please navigate to the connections tab to
                    add them:
                </div>
            </div>

            {/* add link to connections page */}
        </div>
    );
}

export default Preconditions;
