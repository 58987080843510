import React from "react";
import PageTitle from "../../PageTitle";
import FdicInsurance from "./Cards/FdicInsurance";
import Cashback from "./Cards/Cashback";
import FreeWiresAndAch from "./Cards/FreeWiresAndAch";
import FreeAccounts from "./Cards/FreeAccounts";
import MultiEin from "./Cards/MultiEin";
import Startups from "./Cards/Startups";

function MainBenefits() {
    return (
        <div className="p-[60px]">
            <div className="mb-[40px] text-center">
                <PageTitle>
                    A Delightful Banking Experience Built For Healthcare
                    Providers
                </PageTitle>
            </div>
            <div className="grid grid-cols-3 gap-[20px]">
                <FdicInsurance />
                <Cashback />
                <FreeWiresAndAch />
                <FreeAccounts />
                <MultiEin />
                <Startups />
            </div>
        </div>
    );
}

export default MainBenefits;
