import React, { useRef, useEffect, useState } from "react";
import HorizontalTabs, { useGetTabIndex } from "../../HorizontalTabs";
import PageTitle from "../../PageTitle";
import StateInputField from "../../InputField/StateInputField";
import ProvidersFinancials from "./ProvidersFinancials";
import ProvidersConnections from "./ProvidersConnections";
import ProvidersBankBalances from "./ProvidersBankBalances";
import CloseSvg from "../../../assets/Close.svg";

// An array of the tabs to be displayed
const TABS = [
    { key: "financials", label: "Financials" },
    { key: "connections", label: "Connections" },
    { key: "balances", label: "Bank Balances" },
];

function Providers() {
    const { currentTabIndex, setSearchParams } = useGetTabIndex(TABS);
    const scrollContainerRef = useRef(null);
    const [searchTerm, setSearchTerm] = useState("");

    // Scroll to the top of the providers container
    const toggleScroll = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = 0;
        }
    };

    // Tab change handler to be passed to the HorizontalTabs component
    const handleTabChange = (index) => {
        setSearchParams({ tab: TABS[index].key }, { replace: true });
    };

    // Clear the search term
    const clearSearch = () => {
        setSearchTerm("");
    };

    useEffect(() => {
        toggleScroll();
    }, [currentTabIndex]);

    const renderTabContent = () => {
        switch (currentTabIndex) {
            case 0:
                return (
                    <ProvidersFinancials
                        searchTerm={searchTerm}
                        toggleScroll={toggleScroll}
                    />
                );
            case 1:
                return (
                    <ProvidersConnections
                        searchTerm={searchTerm}
                        toggleScroll={toggleScroll}
                    />
                );
            case 2:
                return (
                    <ProvidersBankBalances
                        searchTerm={searchTerm}
                        toggleScroll={toggleScroll}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="flex flex-col justify-start px-[60px] pt-[60px] h-full">
            <PageTitle>Providers</PageTitle>
            <HorizontalTabs
                tabs={TABS.map((tab) => tab.label)}
                initialIndex={currentTabIndex}
                onChange={handleTabChange}
                className="mt-[30px] mb-[10px]"
            />
            <div className="flex flex-row items-center w-full gap-x-[10px] mb-[15px]">
                <StateInputField
                    className="p-2 w-full rounded-full border-2 border-gray-300 focus:outline-flychainPurple-light"
                    name="search"
                    type="text"
                    placeholder="Search for a provider"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <img
                    className="cursor-pointer h-[15px]"
                    src={CloseSvg}
                    alt="Close"
                    onClick={clearSearch}
                />
            </div>
            <div
                ref={scrollContainerRef}
                className="flex overflow-y-auto flex-col grow scrollbar scrollbar-thumb-flychainPurple-light gap-y-[10px] pb-[30px]"
            >
                {renderTabContent()}
            </div>
        </div>
    );
}

export default Providers;
