import React from "react";
import SharedConnections from "../../Connections";

function Connections() {
    return (
        <div className="flex flex-col gap-y-[30px]">
            <div className="font-light text-[15px]">
                You&apos;ve connected the following accounts. If you have
                additional business bank accounts or corporate cards that
                aren&apos;t listed, please add a connection below.
            </div>
            <SharedConnections />
        </div>
    );
}

export default Connections;
