import React from "react";
import PropTypes from "prop-types";

// For use with the useState hook
function StateInputField({
    className,
    name,
    type,
    placeholder,
    value,
    onChange,
}) {
    return (
        <input
            className={
                className ||
                "border-0 px-[16px] py-[12px] rounded-full text-sm font-normal focus:outline-flychainPurple-light"
            }
            name={name}
            type={type}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
        />
    );
}

StateInputField.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

StateInputField.defaultProps = {
    className: "",
    placeholder: "",
};

export default StateInputField;
