import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Pencil from "../../../../assets/Pencil.svg";
import Button from "../../../Button";
import BusinessEntityDetails from "./BusinessEntityDetails";
import BusinessEntityContactInfo from "./BusinessEntityContactInfo";
import { useUpdateBusinessEntityMutation } from "../../../../redux/slices/api";

function BusinessEntityCard({ businessEntity }) {
    const [triggerUpdateBusinessEntity] = useUpdateBusinessEntityMutation();
    const [editingDisplayName, setEditingDisplayName] = useState(false);
    const [displayName, setDisplayName] = useState(
        businessEntity.display_name ||
            businessEntity.dba ||
            businessEntity.legal_name
    );

    function handleSaveDisplayName() {
        triggerUpdateBusinessEntity({
            businessEntityId: businessEntity.id,
            display_name: displayName,
        });
        setEditingDisplayName(false);
    }

    function handleCancelDisplayName() {
        setDisplayName(
            businessEntity.display_name ||
                businessEntity.dba ||
                businessEntity.legal_name
        );
        setEditingDisplayName(false);
    }

    function renderDisplayName() {
        return (
            <div className="flex flex-row gap-x-[15px] items-center">
                {editingDisplayName ? (
                    <>
                        <TextField
                            value={displayName}
                            size="small"
                            onChange={(e) => setDisplayName(e.target.value)}
                        />
                        <div className="flex flex-row items-center gap-x-[10px]">
                            <Button
                                onClick={() => handleSaveDisplayName()}
                                color="primary"
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => handleCancelDisplayName()}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="text-[20px] font-semibold">
                            {displayName}
                        </div>
                        <img
                            src={Pencil}
                            alt="Edit Display Name"
                            className="w-[15px] h-[15px] cursor-pointer"
                            onClick={() => setEditingDisplayName(true)}
                        />
                    </>
                )}
            </div>
        );
    }

    return (
        <div className="flex flex-col p-[10px] gap-y-[10px] border-[1px] rounded-xl shadow-lg">
            <div className="flex flex-col">
                {renderDisplayName()}
                <div className="text-[14px] text-flychainGray">
                    EIN: {businessEntity.ein}
                </div>
                <div className="text-[14px] text-flychainGray">
                    Legal name: {businessEntity.legal_name}
                </div>
            </div>
            <div className="w-full border" />
            <BusinessEntityDetails businessEntity={businessEntity} />
            <div className="w-full border" />
            <BusinessEntityContactInfo businessEntity={businessEntity} />
        </div>
    );
}

BusinessEntityCard.propTypes = {
    businessEntity: PropTypes.object.isRequired,
};

export default BusinessEntityCard;
