import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import SideBarAndContent from "./SideBarAndContent";
import RouterError from "../RouterError";
import Providers from "../AdminDashboard/Providers";
import Revenue from "../FinancialReports/Revenue";
import Expenses from "../FinancialReports/Expenses";
import MerchantDetailView from "../FinancialReports/Expenses/MerchantDetailView";
import BalanceSheet from "../AdminDashboard/FinancialReports/BalanceSheet";
import LedgerLines from "../FinancialReports/LedgerLines";
import IncomeStatement from "../AdminDashboard/FinancialReports/IncomeStatement";
import CashFlow from "../AdminDashboard/FinancialReports/CashFlow";
import FinancialReportsHome from "../AdminDashboard/FinancialReports/FinancialReportsHome";
import BusinessEntityWrapper from "../FinancialReports/BusinessEntityWrapper";
import DateRangeWrapper from "../FinancialReports/DateRangeWrapper";
import BookkeepingDates from "../AdminDashboard/BookkeepingDates";
import Settings from "../Settings";
import BusinessEntityCreator from "../BusinessEntity/BuisnessEntityCreator";
import AdvancedPaymentOnClaims from "../Capital/AdvancedPaymentOnClaims";
import Redirect from "../FinancialReports/Redirect";

const router = createHashRouter([
    {
        path: "/",
        element: <SideBarAndContent />,
        errorElement: <RouterError />,
        children: [
            {
                path: "/providers",
                element: <Providers />,
            },
            {
                path: "/provider/:providerId",
                element: <Redirect />,
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <FinancialReportsHome />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/incomeStatement",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <IncomeStatement />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/incomeStatement/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <LedgerLines />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/cashFlow",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <CashFlow />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/cashFlow/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <LedgerLines />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/balanceSheet",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <BalanceSheet />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/balanceSheet/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <LedgerLines />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/revenue",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <Revenue />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/revenue/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <LedgerLines />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/expenses",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <Expenses />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/expenses/ledgers/:ledgerId",
                element: (
                    <BusinessEntityWrapper>
                        <DateRangeWrapper>
                            <LedgerLines />
                        </DateRangeWrapper>
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/businessEntity/:businessEntityId/financialReports/expenses/merchants/:merchantId",
                element: (
                    <BusinessEntityWrapper>
                        <MerchantDetailView />
                    </BusinessEntityWrapper>
                ),
            },
            {
                path: "/provider/:providerId/capital/advancedPaymentOnClaims",
                element: <AdvancedPaymentOnClaims />,
            },
            {
                path: "/provider/:providerId/settings",
                element: <Settings />,
            },
            {
                path: "/provider/:providerId/settings/createEntity",
                element: <BusinessEntityCreator />,
            },
            {
                path: "/bookkeepingDates",
                element: <BookkeepingDates />,
            },
        ],
    },
]);

function PartnerAdminDashboard() {
    return <RouterProvider router={router} />;
}

export default PartnerAdminDashboard;
