import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Select from "../../Select";
import useGetBusinessEntitiesQuery from "../../../hooks/queriesAndMutations/useGetBusinessEntitiesQuery";

function BusinessEntitySelect() {
    const { data: businessEntities } = useGetBusinessEntitiesQuery({
        includeIncomplete: false,
    });

    const { businessEntityId } = useParams();

    const location = useLocation();

    const navigate = useNavigate();

    const onChange = (option) => {
        const newPath = location.pathname.replace(
            /\/businessEntity\/[^/]+/, // This regex matches /businessEntity/:businessEntityId
            `/businessEntity/${option.value}`
        );
        navigate(newPath, { replace: true });
    };

    if (!businessEntities) return null;

    return businessEntities.length > 1 ? (
        <div className="fixed top-5 right-[60px] z-[1]">
            <Select
                label="Business Entity"
                value={businessEntityId}
                onChange={onChange}
                options={businessEntities.map((businessEntity) => ({
                    value: businessEntity.id,
                    label:
                        businessEntity.display_name ||
                        businessEntity.legal_name,
                }))}
                alignment="right"
            />
        </div>
    ) : null;
}

export default BusinessEntitySelect;
