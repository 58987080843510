import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import ReviewTerms from "../ReviewTerms";
import RouterError from "../RouterError";
import TermsAccepted from "../ReviewTerms/TermsAccepted";
import Capital from "../Capital";
import AdvancedPaymentOnClaims from "../Capital/AdvancedPaymentOnClaims";
import LineOfCredit from "../Capital/LineOfCredit";
import TermLoan from "../Capital/TermLoan";
import SBAPrograms from "../Capital/SBAPrograms";
import SideBarAndContent from "./SideBarAndContent";
import FinancialReportsHome from "../FinancialReports/FinancialReportsHome";
import CashFlow from "../FinancialReports/CashFlow";
import IncomeStatement from "../FinancialReports/IncomeStatement";
import BalanceSheet from "../FinancialReports/BalanceSheet";
import Revenue from "../FinancialReports/Revenue";
import Expenses from "../FinancialReports/Expenses";
import Taxes from "../Taxes";
import LedgerLines from "../FinancialReports/LedgerLines";
import Savings from "../Savings";
import Valuation from "../Valuation";
import Treasury from "../Treasury";
import Banking from "../Banking";
import Settings from "../Settings";
import Paywall from "../Paywall";
import BusinessEntityCreator from "../BusinessEntity/BuisnessEntityCreator";
import BusinessEntityWrapper from "../FinancialReports/BusinessEntityWrapper";
import DateRangeWrapper from "../FinancialReports/DateRangeWrapper";
import Redirect from "../FinancialReports/Redirect";

const router = createHashRouter([
    {
        path: "/",
        element: <SideBarAndContent />,
        errorElement: <RouterError />,
        children: [
            {
                path: "/",
                element: <Redirect />,
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <FinancialReportsHome />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/incomeStatement",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <IncomeStatement />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/incomeStatement/ledgers/:ledgerId",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <LedgerLines />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/cashFlow",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <CashFlow />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/cashFlow/ledgers/:ledgerId",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <LedgerLines />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/balanceSheet",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <BalanceSheet />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/balanceSheet/ledgers/:ledgerId",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <LedgerLines />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/revenue",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <Revenue />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/revenue/ledgers/:ledgerId",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <LedgerLines />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/expenses",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <Expenses />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/businessEntity/:businessEntityId/financialReports/expenses/ledgers/:ledgerId",
                element: (
                    <Paywall>
                        <BusinessEntityWrapper>
                            <DateRangeWrapper>
                                <LedgerLines />
                            </DateRangeWrapper>
                        </BusinessEntityWrapper>
                    </Paywall>
                ),
            },
            {
                path: "/capital",
                element: <Capital />,
            },
            {
                path: "/capital/advancedPaymentOnClaims",
                element: <AdvancedPaymentOnClaims />,
            },
            {
                path: "/capital/lineOfCredit",
                element: <LineOfCredit />,
            },
            {
                path: "/capital/termLoan",
                element: <TermLoan />,
            },
            {
                path: "/capital/sbaPrograms",
                element: <SBAPrograms />,
            },
            {
                path: "/taxes",
                element: (
                    <Paywall>
                        <Taxes />
                    </Paywall>
                ),
            },
            {
                path: "/savings",
                element: (
                    <Paywall>
                        <Savings />
                    </Paywall>
                ),
            },
            {
                path: "/valuation",
                element: (
                    <Paywall>
                        <Valuation />
                    </Paywall>
                ),
            },
            {
                path: "/banking",
                element: (
                    <Paywall>
                        <Banking />
                    </Paywall>
                ),
            },
            {
                path: "/banking/treasury",
                element: (
                    <Paywall>
                        <Treasury />
                    </Paywall>
                ),
            },
            {
                path: "/settings",
                element: <Settings />,
            },
            {
                path: "/settings/createEntity",
                element: <BusinessEntityCreator />,
            },
            {
                path: "terms/:drawId",
                element: <ReviewTerms />,
            },
            {
                path: "terms_accepted", // TODO: in future use camelCase to be consistent with frontend casing
                element: <TermsAccepted />,
            },
        ],
    },
]);

function ProviderDashboard() {
    return <RouterProvider router={router} />;
}

export default ProviderDashboard;
