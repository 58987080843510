import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../../../../SectionTitle";
import { getCurrencyString } from "../../../../../../utils";

function PostCollectionTable({ payments, tableTitle }) {
    return (
        <div className="flex flex-col justify-start">
            <div className="my-[30px]">
                <SectionTitle>{tableTitle}</SectionTitle>
                <table className="border-separate border-spacing-x-[16px] border-spacing-y-[32px]">
                    <thead>
                        <tr>
                            <th>Payment ID</th>
                            <th>Payment Amount</th>
                            <th>Earliest Payment Date</th>
                            <th>Draw ID</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment) => (
                            <tr key={payment.id}>
                                <td>{payment.id}</td>
                                <td>
                                    {getCurrencyString(payment.payment_amount)}
                                </td>
                                <td>{payment.earliest_payment_date}</td>
                                <td>{payment.draw_id}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

PostCollectionTable.propTypes = {
    payments: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    tableTitle: PropTypes.string.isRequired,
};

export default PostCollectionTable;
