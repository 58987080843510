/* eslint-disable camelcase */
import React from "react";
import PlaidLinkButton from "../PlaidLinkButton";
import PlaidAccount from "../PlaidAccount";
import PlaidItemHeader from "../PlaidItemHeader";
import useGetPlaidItemsQuery from "../../hooks/queriesAndMutations/useGetPlaidItemsQuery";

function Connections() {
    const { data: plaidItems } = useGetPlaidItemsQuery();

    return plaidItems ? (
        <div className="flex flex-col gap-y-[30px]">
            <PlaidLinkButton />
            {plaidItems.map((plaidItem) => (
                <div
                    key={plaidItem.item.item_id}
                    className="flex flex-col gap-y-[10px]"
                >
                    <PlaidItemHeader plaidItem={plaidItem} />
                    {plaidItem.accounts.map((account) => (
                        <PlaidAccount
                            key={account.account_id}
                            account={account}
                        />
                    ))}
                </div>
            ))}
        </div>
    ) : null;
}

export default Connections;
