import React from "react";
import PropTypes from "prop-types";
import SharedCard from "../../../Card";

function ImproveFinancialHealthCard({ icon, title, body, action }) {
    return (
        <SharedCard
            showTab={false}
            className="flex flex-col w-[40%] p-[24px] border-[#e6e7eb]"
        >
            <div className="flex flex-col justify-between items-center h-full gap-y-[40px]">
                <div className="flex flex-col items-center">
                    {icon}
                    <div className="text-xl font-semibold mt-[16px]">
                        {title}
                    </div>
                    <div className="text-base font-normal text-center text-flychainGray mt-[20px]">
                        {body}
                    </div>
                </div>
                {action}
            </div>
        </SharedCard>
    );
}

ImproveFinancialHealthCard.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    action: PropTypes.node,
};

ImproveFinancialHealthCard.defaultProps = {
    action: undefined,
};

export default ImproveFinancialHealthCard;
